export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay' // 原先的预授权租借
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge' // 原先的订单付款
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_PAYORDER_SPTOKEN = 'cdb/payment/stripe/multicurrency/payOrder/stripToken' // 目前使用的订单付款
export const STRIPE_PAYORDER = 'cdb/payment/stripe/multicurrency/payOrder' // 信用卡订单付款
export const STRIPE_RECHARGE_WALLET = 'cdb/payment/stripe/multicurrency/rechargeWallet' // 创建钱包充值订单

export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_CLIENTSECRET = 'cdb/payment/stripe/multicurrency/clientSecret' // 获取客户秘钥
export const STRIPE_PREAUTH_AUTO_CONFIRM1 = 'cdb/payment/stripe/authConfirm'
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PAYCONFIRM = 'cdb/payment/stripe/multicurrency/payConfirm/payment' // 确认订单-旧

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'
export const PAYPAL_PREAUTH = "cdb/payment/paypal/multicurrency/preauth"; // paypal创建预授权订单
export const PAYPAL_CONFIRM =
  "cdb/payment/paypal/multicurrency/authConfirm"; // paypal确认订单

export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
// 卡塔尔支付通道
export const MYFATOORAH_PAYORDER = 'cdb/payment/myfatoorah/payOrder' // 订单付款
export const MYFATOORAH_PREAUTH_AUTHORDER = 'cdb/payment/myfatoorah/preAuthOrder' // 信用卡创建预授权订单
export const MYFATOORAH_PREAUTH_OF_CONFIRM = 'cdb/payment/myfatoorah/payment/confirm' // 信用卡创建预授权订单
