export default {
  home: {
    minute: "Minute",
    price_info:
      "Innerhalb {mianfei} Minuten kostenlos, mit einem täglichen Maximum von {currencyName} {fengding}. Die Anzahlung wird automatisch abgezogen, wenn {currencyName} {yajin} vollständig verrechnet wird.",
    feature_1: "Vier Lademethoden, kompatibel mit allen Telefonen",
    feature_2: "Nationale Kreditaufnahme und Rückzahlung",
    feature_3: "Speziell für kabelloses Laden entwickelt",
    tips_1: "Nach Rückgabe der Powerbank wird Ihnen Ihre {currencyName} {yajin} Anzahlung zurückerstattet.",
    // tips_1: '余额不足，需充值满{currencyName}{yajin}押金才可用',
    tips_2: "Laden Sie die Kaution {currencyName} {yajin} auf, um zu mieten, und verwenden Sie sie für eine Rückerstattung",
    tips_3: "Der aktuelle Saldo {currencyName} {myyue} kann direkt gemietet werden",
    submit: "Jetzt aufladen",
    borrow: "Jetzt mieten",
    msg_fail_title: "prompt",
    msg_fail_content: "Lease fehlgeschlagen, bitte versuchen Sie es erneut",
    msg_rentcount: "Sie haben zur Zeit {zujieNum} Bestellungen, die nicht abgeschlossen wurden",
    goborrow: "Geh es mieten!!!",
    nearby: "In der Nähe befindliche Zweige ansehen",
    preauth: "Betrag vor der Genehmigung"
  },
  login: {
    code_title: "SMS-Verifizierung",
    phone_number: "Mobiltelefonnummer",
    verification_code: "Bitte geben Sie den SMS-Bestätigungscode ein",
    captcha_code: "Prüfcode",
    send_code: "Überprüfungscode erhalten",
    login: "Login",
    agree: "Klicken Sie hier, um sich anzumelden, indem Sie angeben, dass Sie gelesen und zugestimmt haben",
    readme: "Nutzungsbedingungen",
    msg_fail_login: "Anmeldung fehlgeschlagen",
    msg_success_login: "Login erfolgreich",
    msg_fail_user: "Fehler beim Abrufen von Benutzerinformationen, möchten Sie es erneut versuchen?",
    sign_in_with: "Anmeldung von Drittanbietern",
    no_sms: "Keine SMS empfangen",
    retry: "Wiedersenden"
  },
  confirm: {
    title: "Zahlung bestätigen",
    paynow: "Sofortige Zahlung",
    SMSConfirm: "Bitte folgen Sie der SMS-Aufforderung, die Sie auf Ihrem Telefon erhalten haben, um die Zahlung vorzunehmen, und klicken Sie auf die Schaltfläche Bestätigen, um fortzufahren.",
    pay_label: "Zahlung erforderlich",
    auth_label: "Vorautorisierung",
    price_tips: "Gutschriftsbetrag innerhalb von 1-5 Tagen nach Auszahlungsantrag",
    // auth_tips: '预授权金额{currencyName}{yajin},归还后按实际租金扣取',
    auth_tips: "Vorautorisierungsbetrag {0} {1}, der bei Rückgabe auf der Grundlage der tatsächlichen Miete abzuziehen ist",
    pay_type: "Bitte wählen Sie die Zahlungsart",
    submit: "Zahlung bestätigen",
    msg_loading: "Laden",
    msg_fail_title: "prompt",
    msg_fail_payment: "Einleitung der Einzahlung fehlgeschlagen",
    msg_fail_order: "Erstellen von Mietaufträgen fehlgeschlagen",
    msg_fail_support: "Die derzeitige Umgebung unterstützt nicht",
    msg_fail_confirm: "bestätigen",
    msg_fail_retry: "Wiederholen",
    msg_fail_cancel: "Abbrechen"
  },
  mine: {
    title: "Persönliches Zentrum",
    wallet: "Meine Brieftasche",
    balance: "Mein Gleichgewicht",
    deposit: "Kaution",
    wallet_in: "Aufladen",
    wallet_out: "Rücknahme",
    wallet_log: "Rücknahme der Aufladung",
    order: "Leasingauftrag",
    coupon: "Gutschein",
    login_tips: "Bitte loggen Sie sich zuerst ein",
    msg_fail_title: "prompt",
    msg_fail_update: "Änderung fehlgeschlagen, bitte versuchen Sie es erneut",
    msg_loading: "Laden...",
    msg_fail_user: "Fehler beim Abrufen von Benutzerinformationen, möchten Sie es erneut versuchen?",
    help: "Hilfe",
    setting: "einrichten",
    logout: "Beenden",
    buy: "Kauf von Ausrüstung"
  },
  msg: {
    no_phone: "Mobilfunknummer ist erforderlich",
    loading: "Laden...",
    title: "prompt",
    success: "Betrieb erfolgreich",
    error: "Unbekannte Ausnahme, bitte kontaktieren Sie den Administrator",
    roleerror: "Unzureichende Berechtigungen, wenden Sie sich bitte an den Administrator",
    neterror: "Netzwerkverbindung fehlgeschlagen, bitte versuchen Sie es später erneut",
    confirm: "bestätigen",
    download: "Download",
    retry: "Wiederholen",
    cancel: "Abbrechen",
    title_fail: "Fehler",
    vieworder: "Bestellungen anzeigen",
    success_payment: "Erfolgreich aufgeladen",
    success_buy: "Kauf erfolgreich",
    fail_payment: "Zahlung fehlgeschlagen",
    keyword: "Stichwort",
    ispayorder: "Bitte bestätigen Sie, ob die Zahlung erfolgt ist?",
    unpay: "Unbezahlt",
    yespay: "Bezahlt",
    notopen: "Noch nicht geöffnet",
    appoffline: 'Die App wurde noch nicht gestartet, bitte warten Sie'
  },
  result: {
    title: "Leasing erfolgreich",
    tips: "Ihre Powerbank ist bereit, schnappen Sie sich, genießen Sie Ihren Tag, ohne Druck!",
    gotit: "Hab's",
    price_plan: "Abrechnungsregeln",
    msg_success: "Leasing erfolgreich",
    msg_tips_loading: "Pop up Powerbank, bitte haben Sie Geduld...",
    msg_result_loading: "Bestätigung der Zahlungsergebnisse...",
    msg_fail_confirm: "bestätigen",
    btn_usage: "Gebrauchsanweisungen",
    btn_return: "Anweisungen zur Rückgabe",
    deposit: "Kaution",
    msg_fail_content: "Anfrage fehlgeschlagen, bitte versuchen Sie es erneut",
    price_label: "/Stunde",
    price_info:
      "{mianfei} ist innerhalb weniger Minuten kostenlos, mit einem täglichen Maximum von {fengding} {ccurrency}. Wenn die Rechnung voll {yajin}{ccurrency}, wird die Anzahlung automatisch abgezogen.",
    tips_1: 'Bitte holen Sie die Powerbank an Position <span class="number">{number}</span> entsprechend dem Diagramm ab',
    tips_2:
      'Bitte bewahren Sie den zurückgegebenen Artikel auf und legen Sie ihn in den Schrank ein und sperren Sie ihn durch Drücken des Pfeils<span class="highlight">[Linie nach unten]</span>',
    msg_note_usage: "Die Powerbank kommt mit drei Arten von Ladekabeln, bitte verwenden Sie diese bei Bedarf",
    msg_note_return: "Bitte stecken Sie das Ladekabel wieder in die entsprechende Position und stecken Sie die Powerbank in den Kartensteckplatz des Gehäuses ein.",
    return_info:
      "Ordnen Sie bei der Rückkehr das Ladekabel auf und stecken Sie das Power Bank Chipende in den leeren Schlitz im Schrank, bis es unten befestigt ist."
  },
  result_msg: {
    loading: "Laden...",
    refresh: "Zum Aktualisieren klicken"
  },
  order: {
    title: "Leasingauftrag",
    in_use: "Im Einsatz",
    ended: "Beendet",
    msg_error: "Laden fehlgeschlagen"
  },
  order_item: {
    order_number: "Bestellnummer",
    venue_rented: "Mietort",
    time_rented: "Mietzeit",
    venue_returned: "Rückgabeort",
    time_returned: "Rückgabefrist",
    charge_standard: "Gebührenordnungen",
    charge_standard_info: "Jede Stunde {pJifei} {pCurrency} (die ersten {pMian} Minuten sind frei)",
    rent_occurred: "Mietgebühren",
    returned: "Rückgabe",
    unreturned: "Nicht zurückgegeben",
    type_0: "Nicht vermietet",
    type_1: "Im Mietvertrag",
    type_2: "aufgehoben",
    type_3: "Rückgabe",
    type_4: "Zweifel haben",
    type_5: "Rückgabe der Überstunden",
    min: "Minute",
    payment: "Zahlung"
  },
  setting: {
    title: "einrichten",
    lang: "Sprache wählen"
  },
  help: {
    title: "Hilfe"
  },
  wallet_in: {
    title: "Guthaben aufladen",
    price_label: "Aufladungsbetrag",
    price_placeholder: "Bitte geben Sie den Ladebetrag ein",
    balance: "Leistungsbilanz",
    price_auto: "Automatisches Aufladen",
    price_tips: "Gutschriftsbetrag innerhalb von 1-5 Tagen nach Auszahlungsantrag",
    submit: "Aufladung bestätigen",
    loading: "Laden...",
    paying: "Zur Zahlungsseite springen...",
    msg_title: "prompt",
    msg_fail_order: "Erstellen der Aufladevorrichtung fehlgeschlagen",
    msg_fail_payment: "Einleitung der Aufladezahlung fehlgeschlagen",
    msg_fail_retry: "Wiederholen"
  },
  wallet_out: {
    title: "Auszahlung des Saldos",
    price_label: "Auszahlungsbetrag",
    price_placeholder: "Bitte geben Sie den Auszahlungsbetrag ein",
    balance: "Leistungsbilanz",
    balance_all: "Alle extrahieren",
    deposit: "Kaution",
    deposit_tips: "Es gibt derzeit Aufträge im Mietvertrag, und die Kaution Ksh {pyajin} ist vorübergehend nicht erstattungsfähig",
    submit: "Sofortiger Entzug",
    apply: "Antrag auf Rücknahme",
    confirm: "bestätigen",
    loading: "Laden...",
    msg_fail_price: "Der Auszahlungsbetrag ist falsch",
    msg_fail_balance: "Der Auszahlungsbetrag darf nicht größer als das aktuelle Guthaben sein",
    msg_fail_submit: "Bitte geben Sie vollständige Informationen an",
    msg_title: "Fehler",
    msg_success_content: "Antrag auf Rücknahme erfolgreich",
    confirmtitle: "prompt",
    confirmcontent: "Die aktuelle Wallet unterstützt keine Auszahlung. Bitte geben Sie Ihre Kontaktdaten an und lassen Sie die Rückerstattung manuell bearbeiten",
    payee_name: "Name",
    payee_bankcard: "Bankkartennummer",
    payee_phone: "Telefon",
    cancelText: "Abbrechen",
    confirmText: "Wieder auftragen",
    noPhone: "Bitte geben Sie Ihre Telefonnummer ein"
  },
  map: {
    title: "Ausrüstung in der Nähe",
    result_msg: "Keine relevante Ausrüstung in der Nähe gefunden",
    msg_title: "Fehler",
    no_support: "Tut mir leid! Ihr Browser unterstützt keine Lokalisierung",
    my_position: "Mein Standort",
    distance: "Entfernung von Ihnen",
    shop_time: "Öffnungszeiten",
    free: "Ausleihbar",
    return: "Kann zurückgegeben werden",
    shop_title: "Angaben zum Händler",
    address: "Adresse",
    go_here: "Hier geht's.",
    '在线': 'on line',
    '离线': 'offline'
  }
};
