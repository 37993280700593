export default {
  home: {
    minute: 'minute',
    price_info: 'Free of charge for the first {mianfei} mins. A maximum daily charge of {currencyName}{fengding}. Deposit will be automatically deducted when rent reaches {currencyName}{yajin}',
    feature_1: 'Adapted to all types of mobile phones',
    feature_2: 'Can be returned to any other site',
    feature_3: 'Specially design - wireless charging(only for modules supported)',
    tips_1: 'Your {currencyName}{yajin} deposit will be refunded to you after you return the power bank.',
    // tips_1: 'A deposit of {currencyName}{yajin} needs to be recharged before renting if the balance is insufficient',
    tips_2: 'Recharge deposit {currencyName}{yajin} can be rented, used up can be refunded',
    tips_3: 'The current balance is {currencyName}{myyue}, which can be leased directly',
    submit: 'Begin rental',
    borrow: 'Rent',
    msg_fail_title: 'Error',
    msg_fail_content: 'Rental failed, please try again',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    goborrow: 'Go to charge!!!',
    nearby: 'View nearby outlets',
    preauth: 'Pre-authorization'
  },
  login: {
    code_title: 'SMS verification',
    phone_number: 'Phone Number',
    verification_code: 'Please enter SMS verification code',
    captcha_code: 'Code',
    send_code: 'Send Code',
    login: 'Login',
    agree: 'By clicking Login, you agree to ',
    readme: `User Agreement`,
    msg_fail_login: 'Login failed',
    msg_success_login: 'Login successful',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    sign_in_with: 'Sign in with',
    no_sms: 'Did not receive text message',
    retry: 'Resend'
  },
  confirm: {
    title: 'Payment Confirmation',
    paynow: 'Immediate payment',
    SMSConfirm: 'Please pay according to the text message received on your phone and click the confirm button to continue.',
    pay_label: 'Amount',
    auth_label: 'Pre-authorization',
    price_tips: 'Your balance can be withdrawn if requested. It might take 1-5 workdays to appear in your payment account.',
    auth_tips: 'A pre-authorization amount of {0}{1} is compulsory. The amount of actual rent occurred will be deducted when the powerbank is returned.',
    pay_type: 'Please select your preferred payment method',
    submit: 'Confirm payment',
    msg_loading: 'Loading...',
    msg_fail_title: 'Warning',
    msg_fail_payment: 'Failed to initiate deposit payment',
    msg_fail_order: 'Failed to create lease order',
    msg_fail_support: 'The system is not supported',
    msg_fail_confirm: 'Confirm',
    msg_fail_retry: 'Retry',
    msg_fail_cancel: 'Cancel',
    noPhone: "Please enter phone number"
  },
  mine: {
    title: 'User Profile',
    wallet: 'My Wallet',
    balance: 'My Balance',
    deposit: 'Deposit',
    wallet_in: 'Charge',
    wallet_out: 'Withdraw',
    wallet_log: 'Wallet Log',
    order: 'Order',
    coupon: 'Coupon',
    login_tips: 'Please Login',
    msg_fail_title: 'Error',
    msg_fail_update: 'Modification failed, please try again',
    msg_loading: 'Loading...',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    help: 'Help',
    setting: 'Setting',
    logout: 'Logout',
    buy: 'Buy equipment'
  },
  msg: {
    no_phone: 'Phone number is required',
    loading: 'Loading...',
    title: 'Hint',
    success: 'Successful operation',
    error: 'Unknown error,please try again later.',
    roleerror: 'Insufficient permissions, please consult the administrator',
    neterror: 'Network connection failed. Please try again later',
    confirm: 'Confirm',
    download: "Download",
    retry: 'Retry',
    cancel: 'Cancel',
    title_fail: 'Warning',
    vieworder: 'View order',
    success_payment: 'Payment completed',
    success_buy: 'Buy completed',
    fail_payment: 'Payment failure',
    keyword: 'keyword',
    ispayorder: 'Please confirm if payment has been made?',
    unpay: 'Unpaid',
    yespay: 'Paid',
    notopen: 'Not yet open',
    appoffline: 'The app has not been launched yet, please wait'
  },
  result: {
    title: 'Rental confirmed',
    tips: 'Your Powerbank is ready, grab it and Enjoy your day, stress free!',
    gotit: 'Got It',
    price_plan: 'Charge Standard',
    msg_success: 'Successfully Rented',
    msg_tips_loading: 'Portable battery is popping up, please wait patiently...',
    msg_result_loading: 'The payment result is being confirmed...',
    msg_fail_confirm: 'Confirm',
    btn_usage: 'Usage note',
    btn_return: 'Return note',
    deposit: 'Deposit',
    msg_fail_content: 'Reqest failed, please try again',
    price_label: 'per hour',
    price_info: 'Free of charge for the first {mianfei} mins，A maximum dialy charge of {ccurrency}{fengding}，Deposit will be automatically deducted when rent reaches {ccurrency}{yajin}',
    tips_1: 'Please collect power bank <span class="number">No.{number}</span> as the picture shown',
    tips_2: 'Please tidy the cables and insert the power bank to bottom with the <span class="highlight">[arrow downward]</span> when return',
    msg_note_usage: 'The power bank is equipped with 3 types of cables and wireless changing,please use as needed.',
    msg_note_return: 'Please tidy this cables and insert the power bank to the bootom with the [arrow downward] when return.',
    return_info: 'When return,set the cables well and insert the side with metal chips into an empty socket till it reaches the bottom and stabilized.'
  },
  result_msg: {
    loading: 'Loading...',
    refresh: 'Refresh'
  },
  order: {
    title: 'Lease Order',
    in_use: 'In use',
    ended: 'Ended',
    msg_error: 'Request failed'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Venue rented',
    time_rented: 'Time rented',
    venue_returned: 'Venue returned',
    time_returned: 'Time returned',
    charge_standard: 'Charge standard',
    charge_standard_info: '{pJifei} {pCurrency} per hour(first {pMian} mins for free)',
    rent_occurred: 'Rent occurred',
    returned: 'Returned',
    unreturned: 'Unreturned',
    type_0: 'Not Completed',
    type_1: 'Renting',
    type_2: 'Canceled',
    type_3: 'Returned',
    type_4: 'Fault occurred',
    type_5: 'Overtime',
    min: 'Min',
    payment: 'Payment'
  },
  setting: {
    title: 'Setting',
    lang: 'Language'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Balance Recharge',
    price_label: 'Recharge amount',
    price_placeholder: 'Please enter the recharge amount',
    balance: 'Current balance',
    price_auto: 'Choose Payment Amount',
    price_tips: 'Your balance can be withdrawn if requested. It might take 1-5 workdays to appear in your payment account.',
    submit: 'Submit',
    loading: 'Loading...',
    paying: 'Jump to the payment page...',
    msg_title: 'Tips',
    msg_fail_order: 'Failed to create recharge order',
    msg_fail_payment: 'Failed to initiate recharge payment',
    msg_fail_retry: 'Retry'
  },
  wallet_out: {
    title: 'Withdrawal',
    price_label: 'Withdrawal amount',
    price_placeholder: 'Please enter the withdrawal amount',
    balance: 'Current balance',
    balance_all: 'Withdraw all',
    deposit: 'Deposit',
    deposit_tips: 'There is an lease order. The deposit of Ksh{pyajin} is non-refundable',
    submit: 'Submit',
    apply: 'Apply',
    confirm: 'Confirm',
    loading: 'Loading...',
    msg_fail_price: 'The withdrawal amount is incorrect',
    msg_fail_balance: 'The withdrawal amount cannot be greater than the current balance',
    msg_fail_submit: 'Please fill in the complete information',
    msg_title: 'Error',
    msg_success_content: 'Apply Refund successful',
    confirmtitle: 'Tips',
    confirmcontent: 'The current wallet does not support cash withdrawal. Please note your contact information and refund manually',
    payee_name: 'Payee Name',
    payee_bankcard: 'Bank card number',
    payee_phone: 'Phone',
    cancelText: 'cancel',
    confirmText: 'Reapply'
  },
  map: {
    title: 'Near the equipment',
    result_msg: 'No equipment was found nearby',
    msg_title: 'error',
    no_support: 'sorry! Your browser does not support location',
    my_position: 'my location',
    distance: 'distance:',
    shop_time: 'hours',
    free: 'loanable',
    return: 'returnable',
    shop_title: 'Merchant details',
    address: 'address',
    go_here: 'Go here',
    '在线': 'on line',
    '离线': 'offline'
  }
}
